import { Col, Row } from 'react-bootstrap';
import { getPaymentMethodText } from '../../../../utils/getPaymentMethodText';
import { formatCurrency } from '../../../../utils/strings';
import { StyledData, StyledDataHeader, StyledSectionTitle } from '../styles';
import getFreightValue from '../../../../utils/getFreightValue';

interface PaymentData {
    method: string;
    total: string;
    commission: number;
    discountPercentage: string;
    interestPercentage: string;
    freightPercentage: number;
}

interface Props {
    paymentData: PaymentData;
}

export default function InvoicePaymentData({ paymentData }: Props) {
    const subtotal = Number(paymentData.total);
    const {
        commission,
        discountPercentage,
        interestPercentage,
        freightPercentage,
    } = paymentData;

    const discount = subtotal * (Number(discountPercentage) / 100);

    const interest =
        (subtotal + commission - discount) * (Number(interestPercentage) / 100);

    const netInvoiceValue = subtotal + commission + interest - discount;

    const freightValue = getFreightValue(netInvoiceValue, freightPercentage);

    const total = netInvoiceValue + freightValue;

    return (
        <>
            <StyledSectionTitle>Pagamento</StyledSectionTitle>

            <Row>
                <Col>
                    <StyledDataHeader>Método de pagamento</StyledDataHeader>
                    <StyledData>
                        {getPaymentMethodText(paymentData.method) || '-'}
                    </StyledData>
                </Col>
                <Col>
                    <StyledDataHeader>Total</StyledDataHeader>
                    <StyledData>{formatCurrency(total)}</StyledData>
                </Col>
            </Row>

            <Row>
                <Col>
                    <StyledDataHeader>Subtotal</StyledDataHeader>
                    <StyledData>{formatCurrency(subtotal)}</StyledData>
                </Col>
                <Col>
                    <StyledDataHeader>Comissão</StyledDataHeader>
                    <StyledData>{formatCurrency(commission)}</StyledData>
                </Col>
            </Row>

            <Row>
                <Col>
                    <StyledDataHeader>Acréscimos</StyledDataHeader>
                    <StyledData>{formatCurrency(interest)}</StyledData>
                </Col>
                <Col>
                    <StyledDataHeader>Desconto</StyledDataHeader>
                    <StyledData>{formatCurrency(discount)}</StyledData>
                </Col>
            </Row>
            <Row>
                <Col>
                    <StyledDataHeader>Frete</StyledDataHeader>
                    <StyledData>{formatCurrency(freightValue)}</StyledData>
                </Col>
            </Row>
        </>
    );
}
