import React, { useCallback, useState } from 'react';
import {
    Col,
    Form,
    Modal,
    OverlayTrigger,
    Row,
    Spinner,
    Tooltip,
} from 'react-bootstrap';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import InvoiceHttpService from '../../../services/http/invoice-http';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import InvoiceDeliveryData from './InvoiceDeliveryData';
import InvoicePaymentData from './InvoicePaymentData';
import InvoicePersonalData from './InvoicePersonalData';
import {
    StyledApproveButton,
    StyledButtonDiv,
    StyledFooterDiv,
} from './styles';
import LoadingModalBody from '../../LoadingModalBody';
import useInvoices from '../../../hooks/useInvoices';
import getSubTotalInvoice from '../../../utils/getSubTotalInvoice';

interface InvoiceApprovalModalProps {
    show: boolean;
    onHide: () => void;
    refetch: () => void;
}

const leadingZero = '0';
const ignoreLeadingZero = 1;

export default function InvoiceApprovalModal({
    show,
    onHide,
    refetch,
}: InvoiceApprovalModalProps) {
    const { selectedInvoice } = useInvoices();

    const [verified, setVerified] = useState(false);
    const [agreed, setAgreed] = useState(false);

    const approvalMutation = useMutation({
        mutationFn: () => InvoiceHttpService.approve(selectedInvoice.id),
        onError: (error) => {
            onHide();
            let message = 'Ocorreu um erro ao aprovar o pedido';

            if (isFriendlyHttpError(error)) {
                message = (error as { message: string }).message;
            }

            toast.error(message);
        },
        onSuccess: async () => {
            onHide();
            refetch();
            toast.success('Pedido aprovado com sucesso');
        },
    });

    const deliveryDateMutation = useMutation({
        mutationFn: () =>
            InvoiceHttpService.updateDeliveryDate(selectedInvoice.id),
        onError: () => {
            onHide();
            toast.error(
                'Ocorreu um erro ao atualizar a estimativa de embarque do pedido',
            );
        },
        onSuccess: async (data) => {
            if (data.data?.updated) {
                onHide();
                refetch();
                toast.info('Estimativa de embarque atualizada');
            }
        },
    });

    const itemsAvailabilityMutation = useMutation({
        mutationFn: () => InvoiceHttpService.recalculate(selectedInvoice.id),
        onError: () => {
            onHide();
            toast.error(
                'Ocorreu um erro ao atualizar a disponibilidade dos itens do pedido',
            );
        },
        onSuccess: async (data) => {
            if (data.data?.updated) {
                onHide();
                await swal({
                    title: 'Atenção',
                    text: 'A disponibilidade dos itens foi atualizada e o pedido deve passar por nova validação',
                    icon: 'warning',
                });
                refetch();
            } else {
                deliveryDateMutation.mutate();
            }
        },
    });

    const commissionMutation = useMutation({
        mutationFn: () =>
            InvoiceHttpService.updateCommission(selectedInvoice.id),
        onError: () => {
            onHide();
            toast.error(
                'Ocorreu um erro ao atualizar o valor líquido da comissão',
            );
        },
        onSuccess: async (data) => {
            if (data.data?.updated) {
                toast.info('O valor líquido da comissão foi atualizado');
            }
        },
    });

    const performUpdates = useCallback(() => {
        commissionMutation.mutate();
        itemsAvailabilityMutation.mutate();
    }, [commissionMutation, itemsAvailabilityMutation]);

    const loading =
        itemsAvailabilityMutation.isLoading ||
        itemsAvailabilityMutation.isIdle ||
        deliveryDateMutation.isIdle ||
        deliveryDateMutation.isLoading ||
        commissionMutation.isLoading ||
        commissionMutation.isIdle;

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="xl"
                onShow={performUpdates}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {loading ? (
                    <LoadingModalBody message="Atualizando comissão, disponibilidade dos itens e estimativa de embarque..." />
                ) : (
                    <>
                        <Modal.Header closeButton className="px-4">
                            <Modal.Title>
                                Pedido Nº {selectedInvoice.id}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-4">
                            <Row>
                                <Col>
                                    <InvoicePersonalData
                                        personalData={{
                                            name: selectedInvoice.customerName,
                                            phone: selectedInvoice.customerPhone.startsWith(
                                                leadingZero,
                                            )
                                                ? selectedInvoice.customerPhone.substring(
                                                      ignoreLeadingZero,
                                                  )
                                                : selectedInvoice.customerPhone,
                                            email: selectedInvoice.customerEmail,
                                            document:
                                                selectedInvoice.customerDocument,
                                        }}
                                    />

                                    <InvoicePaymentData
                                        paymentData={{
                                            method: selectedInvoice.paymentMethod,
                                            total: getSubTotalInvoice(
                                                selectedInvoice,
                                            ),
                                            commission: Number(
                                                selectedInvoice.grossCommission,
                                            ),
                                            discountPercentage:
                                                selectedInvoice.percentage,
                                            interestPercentage:
                                                selectedInvoice.interestPercentage,
                                            freightPercentage:
                                                selectedInvoice?.freightPercentage,
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <InvoiceDeliveryData
                                        deliveryData={{
                                            invoiceId: selectedInvoice.id,
                                            invoiceBranch:
                                                selectedInvoice.branchName,
                                            sellerBranch:
                                                selectedInvoice.seller
                                                    ?.branchName,
                                            address:
                                                selectedInvoice.deliveryAddressDescription,
                                            number: selectedInvoice.deliveryAddressNumber,
                                            complement:
                                                selectedInvoice.deliveryAddressComplement,
                                            neighborhood:
                                                selectedInvoice.deliveryAddressNeighborhood,
                                            city: selectedInvoice.deliveryAddressErpCityName,
                                            state: selectedInvoice.deliveryAddressErpState,
                                            cep: selectedInvoice.deliveryAddressCep,
                                            date: selectedInvoice.deliveryDate,
                                            isFutureSale:
                                                selectedInvoice.futureSale ===
                                                1,
                                            isTriangulation:
                                                selectedInvoice.isTriangulation,
                                        }}
                                        disabled={approvalMutation.isLoading}
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer>
                            <StyledFooterDiv>
                                <Form.Check
                                    type="checkbox"
                                    label={
                                        <span style={{ color: '#f2994a' }}>
                                            Compreendo que o processo de
                                            aprovação é DEFINITIVO e
                                            IRREVERSÍVEL
                                        </span>
                                    }
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        setAgreed(e.target.checked);
                                    }}
                                    style={{
                                        marginTop: '5px',
                                    }}
                                    disabled={approvalMutation.isLoading}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Verifiquei que todas as informações apresentadas acima estão CORRETAS e desejo APROVAR o pedido"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        setVerified(e.target.checked);
                                    }}
                                    style={{
                                        marginBottom: '20px',
                                    }}
                                    disabled={approvalMutation.isLoading}
                                />
                                <StyledButtonDiv>
                                    {verified && agreed ? (
                                        <StyledApproveButton
                                            onClick={() =>
                                                approvalMutation.mutate()
                                            }
                                            disabled={
                                                approvalMutation.isLoading
                                            }
                                        >
                                            {approvalMutation.isLoading ? (
                                                <>
                                                    <Spinner
                                                        animation="border"
                                                        size="sm"
                                                    />
                                                    <span>Aprovando...</span>
                                                </>
                                            ) : (
                                                'Aprovar'
                                            )}
                                        </StyledApproveButton>
                                    ) : (
                                        <OverlayTrigger
                                            key="disabledApproveButton"
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip-bottom">
                                                    Para poder aprovar o pedido,
                                                    você deve confirmar ciência
                                                    para as duas declarações
                                                    acima
                                                </Tooltip>
                                            }
                                        >
                                            <StyledApproveButton disabled>
                                                Aprovar
                                            </StyledApproveButton>
                                        </OverlayTrigger>
                                    )}
                                </StyledButtonDiv>
                            </StyledFooterDiv>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
        </>
    );
}
