import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router-dom';
import formatDate from '../../../utils/formatDate';
import getInvoiceExpirationDate from '../../../utils/getInvoiceExpirationDate';
import getTotalInvoice from '../../../utils/getTotalInvoice';
import { formatCurrency } from '../../../utils/strings';
import { ReactComponent as ReactMagnifierIcon } from '../../../assets/icons/magnifier.svg';
import useInvoices from '../../../hooks/useInvoices';
import BudgetTableRowDropdown from '../BudgetTableRowDropdown';
import getFreightValue from '../../../utils/getFreightValue';

interface BudgetTableRowProps {
    budget: any;
}

export default function BudgetTableRow({ budget }: BudgetTableRowProps) {
    const history = useHistory();

    const { filterTerm } = useInvoices();

    const netInvoiceValue = getTotalInvoice(budget);
    const freightValue = getFreightValue(
        netInvoiceValue,
        budget.freightPercentage,
    );
    const totalInvoice = netInvoiceValue + freightValue;

    return (
        <tr>
            <td>
                <Highlighter
                    autoEscape
                    highlightClassName="highlight-term"
                    searchWords={[filterTerm]}
                    textToHighlight={budget?.id.toString()}
                />
            </td>
            <td>{formatDate(budget.finishedAt)}</td>
            <td>
                <Highlighter
                    autoEscape
                    highlightClassName="highlight-term"
                    searchWords={[filterTerm]}
                    textToHighlight={budget.customerName}
                />
            </td>
            <td>
                <Highlighter
                    autoEscape
                    highlightClassName="highlight-term"
                    searchWords={[filterTerm]}
                    textToHighlight={budget.seller?.name}
                />
            </td>
            <td>{formatCurrency(totalInvoice)}</td>
            <td>
                {getInvoiceExpirationDate(budget.finishedAt, budget.status)}
            </td>
            <td className="d-flex">
                <BudgetTableRowDropdown budget={budget} />
                <ReactMagnifierIcon
                    fill="#BDBDBD"
                    width="18"
                    height="18"
                    className="my-auto"
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        history.push(`/orcamentos/${budget.id}`);
                    }}
                />
            </td>
        </tr>
    );
}
