import Request from './request';

export default class FreightsHttpService {
    private static uri = 'freights';

    public static async readOne(addressCep: string) {
        return Request.get(`${this.uri}/${addressCep}`);
    }

    public static async readFreightRate(stateAcronym: string) {
        return Request.get(`${this.uri}/rate/${stateAcronym}`);
    }
}
