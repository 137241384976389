/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-const */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { useClipboard } from '@mantine/hooks';
import { Col, Row, Spinner, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import { ReactComponent as BarcodeSvg } from '../../../../assets/icons/barcode.svg';
import PaymentHttpService from '../../../../services/http/payment-http';
import {
    StyledPageSubTitle,
    StyledPageTitle,
} from '../../../../styles/pageTitle';
import getTotalInvoice from '../../../../utils/getTotalInvoice';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';
import { formatCurrency } from '../../../../utils/strings';
import InvoiceSummary from '../../InvoiceSummary';
import { StyledButton } from './styles';
import useInvoices from '../../../../hooks/useInvoices';
import getFreightValue from '../../../../utils/getFreightValue';

const Checkout = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [checkout, setCheckout] = useState({} as any);
    const [boletoUrl, setBoletoUrl] = useState('');
    const { showPaymentMethodModal, setShowPaymentMethodModal } = useInvoices();
    const clipboard = useClipboard({ timeout: 500 });

    async function getCheckout(): Promise<void> {
        setLoading(true);

        try {
            const resp = await PaymentHttpService.generateCheckout(
                props.invoice?.id,
            );

            if (resp.data.isError) {
                toast.error(resp.data.message);

                setLoading(false);

                return;
            } else {
                if (resp.data.mundipaggBoletoUrl) {
                    setBoletoUrl(resp.data.mundipaggBoletoUrl);
                    setCheckout({});
                } else {
                    setBoletoUrl('');
                    setCheckout({
                        token: resp?.data?.mundipaggCheckoutToken,
                        url: resp?.data?.mundipaggCheckoutUrl,
                    });
                }
            }
        } catch (err) {
            let error = err as any;

            if (isFriendlyHttpError(error)) {
                toast.error(error.message);

                setLoading(false);
                setCheckout({});
                setBoletoUrl('');

                return;
            }

            toast.error(
                'Erro ao gerar checkout para pagamento, entre em contato com nosso time comercial',
            );

            setLoading(false);
            setCheckout({});
            setBoletoUrl('');

            return;
        }

        setLoading(false);
    }

    function removeDuplicateMundipaggModal() {
        const mundipaggModals = document.getElementsByClassName(
            'mundicheckout-modal',
        );

        if (mundipaggModals[1]) {
            mundipaggModals[1].remove();
        }
    }

    useEffect(() => {
        if (props.invoice?.id && showPaymentMethodModal) {
            getCheckout();
        } else {
            setBoletoUrl('');
            setCheckout({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.invoice]);

    const totalInvoice = Number(getTotalInvoice(props.invoice));

    const freightValue = getFreightValue(
        totalInvoice,
        props.invoice?.freightPercentage,
    );

    return (
        <Modal {...props} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title
                    className={'ml-3'}
                    id="contained-modal-title-vcenter"
                >
                    <Row>
                        <Col sm={1}>
                            <BarcodeSvg />
                        </Col>
                        <Col>
                            <div className={'ml-2'}>
                                Pagamento via Mundipagg{' '}
                                {formatCurrency(totalInvoice + freightValue)}
                            </div>
                        </Col>
                    </Row>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'ml-3'}>
                <Row>
                    <Col sm={6}>
                        <Row>
                            <div className={'mt-4 ml-3'}>
                                <StyledPageTitle>Vencimento</StyledPageTitle>
                                <StyledPageSubTitle>
                                    Caso não seja pago até a data informada, o
                                    pedido será automaticamente cancelado.
                                </StyledPageSubTitle>
                            </div>
                        </Row>
                        <Row className={'align-items-center'}>
                            <Col className={'mt-4 ml-3'}>
                                <Row>
                                    <StyledPageTitle>
                                        {boletoUrl === ''
                                            ? 'Prossiga para o Checkout:'
                                            : 'Boleto disponível:'}{' '}
                                    </StyledPageTitle>{' '}
                                </Row>
                                {!loading ? (
                                    <>
                                        {boletoUrl === '' ? (
                                            <>
                                                {' '}
                                                <Row>
                                                    <Helmet>
                                                        <script src="https://checkout.mundipagg.com/v1/lightbox.js" />
                                                    </Helmet>
                                                    <Button
                                                        as="a"
                                                        data-mundicheckout="lightbox"
                                                        data-mundicheckout-token={
                                                            checkout?.token
                                                        }
                                                        data-mundicheckout-default
                                                        onClick={() => {
                                                            setShowPaymentMethodModal(
                                                                false,
                                                            );

                                                            removeDuplicateMundipaggModal();
                                                        }}
                                                    >
                                                        Pagar
                                                    </Button>
                                                </Row>
                                            </>
                                        ) : (
                                            <>
                                                <Row>
                                                    <Button
                                                        className="w-100"
                                                        onClick={() => {
                                                            window.open(
                                                                boletoUrl,
                                                                '_blank',
                                                            );
                                                        }}
                                                    >
                                                        Abrir boleto
                                                    </Button>
                                                </Row>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <Row>
                                        <Spinner animation={'border'} />
                                    </Row>
                                )}
                                {boletoUrl === '' && (
                                    <>
                                        <Row>
                                            <StyledButton
                                                as="a"
                                                onClick={() => {
                                                    clipboard.copy(
                                                        checkout?.url,
                                                    );
                                                    toast.success(
                                                        'Link do checkout copiado.',
                                                    );
                                                }}
                                                className={
                                                    'align-items-center mt-2'
                                                }
                                            >
                                                ou clique aqui para copiar{' '}
                                                <HiOutlineClipboardCopy
                                                    onClick={() => {
                                                        clipboard.copy(
                                                            checkout?.url,
                                                        );
                                                        toast.success(
                                                            'Link do checkout copiado.',
                                                        );
                                                    }}
                                                />
                                            </StyledButton>
                                        </Row>
                                    </>
                                )}
                            </Col>

                            <div className={'mt-4 ml-3'}>
                                <StyledPageTitle>
                                    Prazo de entrega
                                </StyledPageTitle>
                                <StyledPageSubTitle>
                                    É contado a partir da confirmação de
                                    pagamento pelo banco, o que pode levar até 2
                                    dias úteis.
                                </StyledPageSubTitle>
                            </div>
                        </Row>
                        <Row>
                            <div className={'mt-4 ml-3'}>
                                <StyledPageTitle>Atenção:</StyledPageTitle>
                                <StyledPageSubTitle>
                                    Não será enviado uma cópia impressa do
                                    boleto para seu endereço - Desabilite o
                                    recurso anti pop-up caso você use.
                                </StyledPageSubTitle>
                            </div>
                        </Row>
                    </Col>
                    <Col sm={1}></Col>
                    <Col>
                        <InvoiceSummary invoice={props.invoice} />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className={'d-block'} />
        </Modal>
    );
};

export default Checkout;
